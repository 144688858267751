<template>
    <b-sidebar
     id="services-form-sidebar"
     :visible="isServicesFormSidebarActive"
     bg-variant="white"
     sidebar-class="sidebar-lg"
     shadow
     backdrop
     no-header
     right
     @shown="getData(servicesData)"
     @hidden="resetForm"
     @change="(val) => $emit('update:is-services-form-sidebar-active', val)"
   >

   <template #default="{ hide }"> 
       <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
           <h5 class="mb-0">
               Services  Form
           </h5>
   
           <feather-icon
               class="ml-1 cursor-pointer"
               icon="XIcon"
               size="16"
               @click="hide"
           />
       </div>
       <validation-observer
         #default="{ handleSubmit }"
         ref="refFormObserver"
       >
         <b-form
           class="p-2"
           @submit.prevent="handleSubmit(onSubmit)"
           @reset.prevent="resetForm"
         >
            <b-tabs v-model="tabIndex">
                <b-tab title="Content">
                    <validation-provider
             #default="validationContext"
             name="Title"
             rules="required"
           >
           
             <b-form-group
               label="Title"
               label-for="title"
             >
               <b-form-input
                 id="title"
                 v-model="Data.title"
                 autofocus
                 :state="getValidationState(validationContext)"
                 trim
                 placeholder="Title"
                 @input="changeTitle"
               />
 
               <b-form-invalid-feedback>
                 {{ validationContext.errors[0] }}
               </b-form-invalid-feedback>
             </b-form-group>
           </validation-provider>
           <validation-provider
             #default="validationContext"
             name="slug"
             rules="required"
           >
             <b-form-group
               label="Slug"
               label-for="slug"
             >
               <b-form-input
                 id="slug"
                 v-model="Data.slug"
                 autofocus
                 :state="getValidationState(validationContext)"
                 trim
                 placeholder="Slug"
               />
 
               <b-form-invalid-feedback>
                 {{ validationContext.errors[0] }}
               </b-form-invalid-feedback>
             </b-form-group>
           </validation-provider>
           <validation-provider
            #default="validationContext"
            name="intro"
            rules="min:0"
           >
            <b-form-group
                label="Short Description"
                label-for="intro"
            >
                <b-form-textarea v-model="Data.intro" />
                <b-form-invalid-feedback>
                 {{ validationContext.errors[0] }}
               </b-form-invalid-feedback>
            </b-form-group>
           </validation-provider>
           <validation-provider
            #default="validationContext"
            name="description"
            rules="min:0"
           >
            <b-form-group
                label="Description"
                label-for="description"
            >
               
                <quill-editor :options="snowOption" v-model="Data.description" />
                <b-form-invalid-feedback>
                 {{ validationContext.errors[0] }}
               </b-form-invalid-feedback>
            </b-form-group>
           </validation-provider>
           
           <validation-provider
             #default="validationContext"
             name="IsActive"
             rules="min:0"
           >
             <b-form-group
               label="Is Active"
               label-for="IsActive"
             >
             
               <b-form-checkbox
               id="isactive"
                               checked="true"
                               class="custom-control-success"
                               v-model="Data.is_active"
                               switch
                 />
 
               <b-form-invalid-feedback>
                 {{ validationContext.errors[0] }}
               </b-form-invalid-feedback>
             </b-form-group>
           </validation-provider>
           <div class="border rounded p-2">
                      <h4 class="mb-1">
                        Featured Image
                      </h4>
                      <b-media
                        no-body
                        vertical-align="center"
                        class="flex-column flex-md-row"
                      >
                        <b-media-aside>
                          <b-img
                            ref="refPreviewEl"
                            :src="Data.featuredImage"
                            height="110"
                            width="170"
                            class="rounded mr-2 mb-1 mb-md-0"
                          />
                        </b-media-aside>
                        <b-media-body>
                          <small class="text-muted">Required image resolution 800x400, image size 10mb.</small>
                          <b-card-text class="my-50">
                           
                          </b-card-text>
                          <div class="d-inline-block">
                            <b-form-file
                              ref="refInputEl"
                              v-model="Data.servicesFile"
                              accept=".jpg, .png, .gif"
                              placeholder="Choose file"
                              @input="inputImageRenderer"
                            />
                          </div>
                          <b-card-text class="my-50">
                            <b-button  v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" v-if="Data.cover_image" @click="revomeImage">Remove Image</b-button>
                          </b-card-text>
                        </b-media-body>
                      </b-media>
                  </div>
                </b-tab>
                <b-tab title="SEO">
                  
                    <validation-provider
                        #default="validationContext"
                        name="meta_title"
                        rules="min:0"
                    >
                        <b-form-group
                        label="Meta Title"
                        label-for="meta_title"
                        >
                        <b-form-input
                            id="meta_title"
                            v-model="Data.meta_title"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            placeholder="Meta Title"
                        />
            
                        <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider> 
                    <validation-provider
                        #default="validationContext"
                        name="meta_description"
                        rules="min:0"
                    >
                        <b-form-group
                        label="Meta Description"
                        label-for="meta_description"
                        >
                        <b-form-input
                            id="meta_description"
                            v-model="Data.meta_description"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            placeholder="Meta Description"
                        />
            
                        <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>   
                    <validation-provider
                        #default="validationContext"
                        name="meta_keywords"
                        rules="min:0"
                    >
                        <b-form-group
                        label="Meta Keywords"
                        label-for="meta_keywords"
                        >
                        <b-form-input
                            id="meta_keywords"
                            v-model="Data.meta_keywords"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            placeholder="Meta Keywords"
                        />
            
                        <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>      
                </b-tab>
            </b-tabs>
           


           <div class="d-flex mt-2">
             <b-button
               v-ripple.400="'rgba(255, 255, 255, 0.15)'"
               variant="primary"
               class="mr-2"
               type="submit"
             >
               Submit
             </b-button>
             <b-button
               v-ripple.400="'rgba(186, 191, 199, 0.15)'"
               type="button"
               variant="outline-secondary"
               @click="hide"
             >
               Cancel
             </b-button>
           </div>
         </b-form>
         
         
       </validation-observer>
   </template>
   </b-sidebar>
</template>
<script>
 import {
   BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab
 } from 'bootstrap-vue'
 //upload
 import {
  BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile
 } from 'bootstrap-vue'
 import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'

 import { ValidationProvider, ValidationObserver } from 'vee-validate'
 import { ref } from '@vue/composition-api'
 import { required, alphaNum, email, password,confirmed } from '@validations'
 import formValidation from '@core/comp-functions/forms/form-validation'
 import Ripple from 'vue-ripple-directive'
 import vSelect from 'vue-select'
 import countries from '@/@fake-db/data/other/countries'
 import store from '@/store'
 import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
 import { useToast } from 'vue-toastification/composition'


import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { slugify } from '@/@fake-db/utils'

import { quillEditor } from 'vue-quill-editor'
export default {
   components: {
     BSidebar,
     BForm,
     BFormGroup,
     BFormInput,
     BFormTextarea,
     BFormCheckbox,
     BFormInvalidFeedback,
     BButton,
     BTabs, BTab,
     BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,
     vSelect,
     quillEditor,
     // Form Validation
     ValidationProvider,
     ValidationObserver,
   },
   directives: {
     Ripple,
   },
   model: {
     prop: 'isServicesFormSidebarActive',
     event: 'update:is-services-form-sidebar-active',
   },
   props: {
     isServicesFormSidebarActive: {
       type: Boolean,
       required: true,
     },
    
     servicesData: {
       type: Object,
       required: false,
     },
   },
   
   data(){
      return {
        snowOption: {
          theme: 'snow',
        },
      }
   },
   setup(props, { emit }) {
       const toast = useToast()
       const tabIndex = ref(0);
       const featuredImage = ref({});
       const userStorage = JSON.parse(localStorage.getItem('userData'));
       const blankData = {
           featuredImage:require('@/assets/images/no-image.png'),
           icon:'',
           created_by:userStorage.id,
           created_at:null,
           title: '',
           slug:'',
           intro:'',
           description:'',
           is_active:true,
           servicesFile:null,
           meta_title:'',
           meta_description:'',
           meta_keywords:''
       }
       const Data = ref(JSON.parse(JSON.stringify(blankData)))
       const resetData = () => {
           Data.value = JSON.parse(JSON.stringify(blankData))
       }
   
       const getData = (data)=>{
            tabIndex.value = 0;
            refPreviewEl.value.src = require('@/assets/images/no-image.png');
           if(data){
                data.featuredImage = data.icon?data.fullpath:Data.value.featuredImage;
               data.is_active = data.is_active?true:false;
               Data.value  = JSON.parse(JSON.stringify(data));
           }
       }
       const onSubmit = () => {
        let formData = new FormData();
            Data.value.is_active = Data.value.is_active?1:0;
            Data.value.path = '/var/www/html/uploadfiles/uploads/smk/services/';
            Data.value.savepath = 'uploads/smk/services/';
            for(const [key, value] of Object.entries(Data.value)) {
              formData.append(key, value);
             
            
            }
           if(Data.value.id){
             
               store.dispatch('app-services/updateData', {formData:formData,data:Data.value})
               .then((res) => {
                   
                   if(res.data.success){
                   toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Success`,
                       icon: 'BellIcon',
                       variant: 'success',
                       text: `${res.data.message}`,
                       },
                   });
                   emit('refetch-data')
                   emit('update:is-services-form-sidebar-active', false)
                   }else{
                   
                   toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Error`,
                       icon: 'BellIcon',
                       variant: 'danger',
                       text: `${res.data.message}`,
                       },
                   });
               
                   
                   }
               
               })
           }else{
               store.dispatch('app-services/addData', {formData:formData})
               .then((res) => {
                   
                   if(res.data.success){
                   toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Success`,
                       icon: 'BellIcon',
                       variant: 'success',
                       text: `${res.data.message}`,
                       },
                   });
                   emit('refetch-data')
                   emit('update:is-services-form-sidebar-active', false)
                   }else{
                   
                   toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Error`,
                       icon: 'BellIcon',
                       variant: 'danger',
                       text: `${res.data.message}`,
                       },
                   });
               
                   
                   }
               
               })
           }

       }

       
        const {
       refFormObserver,
       getValidationState,
       resetForm,
     } = formValidation(resetData)
     //upload
     const refInputEl = ref(null)
      const refPreviewEl = ref(null)
     const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { 
       
        refPreviewEl.value.src = base64 
     })
     const revomeImage = ()=>{
      Data.value.featuredImage = require('@/assets/images/no-image.png');
      Data.value.cover_image = '';
     }
     const changeTitle  = () => {
        Data.value.slug =  slugify(Data.value.title )
       }
     return {
       Data,
       onSubmit,
      
       refFormObserver,
       getValidationState,
       resetForm,
       getData,
       tabIndex,
       refInputEl,
       refPreviewEl,
       inputImageRenderer,
       revomeImage,
       changeTitle
       
     }
  
   }
   
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>